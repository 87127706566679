import React from "react";
import Picture from "../common/Picture";

const BottomSection = ({
  title,
  description,
  image,
  descriptionStyle = {},
}) => {
  return (
    <section
      className={`row article-section mx-auto d-center text-center`}
      style={{
        maxWidth: "100%",
      }}
    >
      <div className="col-12 d-center text-left text-md-center flex-column px-0">
        <div className="article-item px-4 pb-5">
          <header>
            <h2 className="h3">{title}</h2>
          </header>
          <p
            className="mb-0 article-description mx-auto parag"
            style={{
              maxWidth: 650,
              ...descriptionStyle,
            }}
          >
            {description}
          </p>
        </div>
        <Picture
          img={{
            url: image,
            alt: "Perceval depuis 1993",
          }}
          style={{
            minHeight: 300,
            objectFit: "cover",
          }}
          full
        />
      </div>
    </section>
  );
};

export default BottomSection;
