import Link from "next/link";
import { useEffect } from "react";
import Footer from "../components/Footer";
import FrontHeader from "../components/FrontHeader";
import ArticleItemLeft from "../components/HomePage/ArticleItemLeft";
import ArticleItemRight from "../components/HomePage/ArticleItemRight";
import BottomSection from "../components/HomePage/BottomSection";
import TitleSection from "../components/HomePage/TitleSection";
import Layout from "../components/Layout";
import Animated from "../components/common/Animated";
import HeroSection from "../components/common/HeroSection/HeroSection";
import WordChange from "../components/common/WordChange/WordChange";
import externalTools from "../helpers/externalTools";

const Index = (props) => {
  useEffect(() => {
    externalTools.eventNavigation("index", "accueil");
  }, []);

  return (
    <Layout>
      <FrontHeader />

      <main>
        <HeroSection name="home">
          <TitleSection
            title={
              <>
                <strong className="text-primary d-block pb-2 pb-md-3">
                  Perceval
                </strong>
                l'assurance chevaleresque
                <br />
                <WordChange />
              </>
            }
            description={
              <>
                Jetez aux oubliettes ce que vous pensiez savoir des assurances.
              </>
            }
            cta="Obtenir mon offre"
          />
        </HeroSection>

        <div className="main-container">
          <div className="central-section">
            <ArticleItemLeft
              title="Simple"
              titleClassname={"assurance-en-ligne-h3"}
              text="Avec nos services en ligne, trouver des solutions d’assurances adaptées devient un jeu d’enfant !"
              image="/front/home/simple.webp"
              alt="Assurance en ligne"
            />

            <ArticleItemRight
              title="Là pour vous depuis 30 ans"
              text={
                <>
                  Les nouvelles technologies sont pratiques, mais rien ne vaut
                  le contact humain.
                  <br />
                  <br />À vos côtés dès le premier jour, nous garantissons une{" "}
                  <span className="fwb">réponse éclairée</span> à vos besoins
                  d’assurance, et un devoir de{" "}
                  <span className="fwb">conseil sans faille.</span>
                </>
              }
              image="/front/home/la-pour-vous.webp"
              alt="A votre service depuis 30 ans"
            />
            <ArticleItemLeft
              title="Réactive"
              text={
                <>
                  Les imprévus font partie de la vie d’entreprise…
                  <br />
                  <br />
                  Attentifs à l’évolution de votre activité, nous ajustons vos
                  contrats dès que nécessaire et assurons des{" "}
                  <span className="fwb">remboursements rapides</span> en cas de
                  sinistre, pour une{" "}
                  <span className="fwb">tranquillité d’esprit</span> maximale.
                </>
              }
              image="/front/home/reactive.webp"
              alt="A votre service depuis 30 ans"
            />
            <ArticleItemRight
              title="Sécurisée"
              titleClassname={"assurance-en-ligne-h3"}
              text={
                <>
                  Créez un accès sécurisé et personnalisé à vos données
                  d’assurance pour les membres de votre équipe et vos
                  partenaires de confiance (experts-comptables, clients,
                  fournisseurs).
                  <br />
                  <br />
                  <span className="fwb">
                    Gérez les contrats d’assurance{" "}
                  </span>{" "}
                  de vos différentes entreprises{" "}
                  <span className="fwb">en un seul et même endroit</span> :
                  conditions, attestations, factures. Supervisez l’ensemble des
                  événements, documents, et{" "}
                  <span className="fwb">paiements en un clic !</span>
                </>
              }
              image="/front/home/securisee.webp"
              alt="Assurance en ligne"
            />
          </div>
        </div>

        <BottomSection
          title="Partenaire des meilleurs"
          description="Vos polices d’assurance sont garanties par des assureurs basés en France depuis plus de 160 ans. Vous êtes couverts par les meilleurs et les plus solides."
          image="/front/home/partenaire-des-meilleurs.webp"
          full
        />
        <div
          className={`row article-section mx-auto d-center text-center  pt-0`}
          style={{
            maxWidth: "100%",
          }}
        >
          <div className="col-12 d-center flex-column px-0">
            <div className="text-center">
              <Animated type="btn">
                <Link href="/assurer-mon-entreprise">
                  <a className="main-cta cta-bottom btn btn-secondary d-inline-flex justify-content-center align-items-center tracking-gtm-FormView">
                    J'assure mon entreprise
                  </a>
                </Link>
              </Animated>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  );
};

export default Index;
